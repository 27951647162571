<template>
  <div class="products">
    <div class="section-products-by-category">
      <div v-for="({ id, slug, prods }, index) in productsByCompany" :key="index" :id="slug">
        <div class="company-header">
          <a 
            :href="companiesWithProducts[id]?.link_store[0]"
            target="_blank"
          >
            <q-img class="logo" :src="companiesWithProducts[id]?.logo" />
          </a>
        
          <div class="company-header-inner">
            <a 
              role="button" 
              class="btn-go-to-store"
              target="_blank"
              :href="companiesWithProducts[id]?.link_store[0]"
            >
              Ir para {{ slug }}
            </a>
            <a 
              v-if="companiesWithProducts[id]?.voucher?.discount" 
              @click="copyVoucher($event, id)"
              class="btn-coupon" 
              role="button"
            >
              <span 
                class="coupon-text"
                v-html="btnVoucherText(companiesWithProducts[id]?.voucher)" 
              />
              <div class="coupon-icon">
                <i class="fas fa-copy"></i>
              </div>
            </a>
            <a role="button" class="btn-mobile-options">
              <q-icon name="more_vert" />
              <q-menu 
              style="box-shadow: 0 1px 2px 0 rgba(60,64,67,.3),0 2px 6px 2px rgba(60,64,67,.15) !important"
              >
               <q-list style="min-width: 100px">
                <q-item 
                  clickable v-close-popup 
                  @click="goToStore(companiesWithProducts[id]?.link_store[0])"
                >
                  <q-item-section>Ir para {{ slug }}</q-item-section>
                </q-item>
               </q-list>
              </q-menu>
            </a>
          </div>
        </div>

        <div class="divider" />

        <!-- Lista de produtos por categoria -->
        <div class="products-by-category">
          <div v-for="(product, productIndex) in prods.slice(0, productsToShow[index])" :key="productIndex">
            <Product 
              isLPCentralized="true"
              :data="product"
              class="product"
            />
          </div>
        </div>

        <!-- Botão 'Ver mais' -->
        <a 
          role="button"
          class="see-more"
          v-if="productsToShow[index] < prods.length" 
          @click="loadMore(index)"
        >
          Ver mais
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, computed } from 'vue';
import { useGetters, useActions } from 'vuex-composition-helpers';

import Product from '../Product.vue';

const props = defineProps({
  productsByCompany: Array,
});

const companiesWithProducts = ref(null);
const productsToShow = ref([]); /* Estado que mantém quantos produtos exibir para cada empresa */

const { setOnlyCompaniesWithProductsLPCentralized } = useActions({
  setOnlyCompaniesWithProductsLPCentralized: "public_landingpage/setOnlyCompaniesWithProductsLPCentralized"
})

const { getInfluencerVoucherLPCentralized, getCompaniesLinksLPCentralized } = useGetters({
  getInfluencerVoucherLPCentralized: "public_landingpage/getInfluencerVoucherLPCentralized",
  getCompaniesLinksLPCentralized: "public_landingpage/getCompaniesLinksLPCentralized"
})


/* Inicializa o número de produtos a serem mostrados (4 para cada empresa) */
props.productsByCompany.forEach(() => {
  productsToShow.value.push(4);
});


companiesWithProducts.value = props.productsByCompany.reduce((acc, item) => {
    acc[item.id] = {
      slug: item?.slug
    };

    return acc;
}, {});


const idsCompaniesWithProducts = computed(() => Object.keys(companiesWithProducts.value))


watch(getInfluencerVoucherLPCentralized, (vouchers) => {
  for (const voucher of vouchers) {
    const companyID = voucher?.parceiro_id;

    if (!companyID) continue; // Se companyID não existir, pula para a próxima iteração

    if (idsCompaniesWithProducts.value.includes(companyID.toString())) {

      if (companiesWithProducts.value[companyID]) {
        companiesWithProducts.value[companyID].voucher = {
          ...voucher    
        };
      }
    }
  }
}, { immediate: true });


watch(getCompaniesLinksLPCentralized, (links) => {
  for (const link of links) {
    const companyID = link?.parent_id;

    if ( !companyID ) continue; // Se partnerID não existir, pula para a próxima iteração

    if (idsCompaniesWithProducts.value.includes(companyID.toString())) {

      if (companiesWithProducts.value[companyID]) {
        companiesWithProducts.value[companyID].link_store = link?.link_store
        companiesWithProducts.value[companyID].logo = link?.link_company_image
      }

    }
  }
}, { immediate: true })

watch(companiesWithProducts.value, (newValue) => {
  if ( newValue ) setOnlyCompaniesWithProductsLPCentralized(newValue)
}, { deep: true, immediate: true })



/* =========== METHODS =========== */

/* Copia o número do voucher */
const copyVoucher = async ( event, companyID ) => {
  const getDataCompanyByID = companiesWithProducts.value[companyID];
  const voucherNumber = getDataCompanyByID?.voucher?.number;
  const couponButton = event.currentTarget;

  try {
    await navigator.clipboard.writeText( voucherNumber )

    changeCouponIcon(couponButton)
  } catch (error) {
    console.log('Houve um erro ao copiar voucher', error)
  }
}


const btnVoucherText = ( voucher ) => {
  const type = voucher?.type_discount == "percent" ? "%" : ""

  return `
    Cupom ${voucher.discount}${type} OFF
  `
}


/* Quando clica no botão de copiar voucher, muda para o icone de 'Copiado' (check) */
const changeCouponIcon = (couponButton) => {
  const couponIcon = couponButton.querySelector('.coupon-icon');
  const couponText = couponButton.querySelector('.coupon-text');
  const getCouponText = couponText.innerText;

  couponIcon.innerHTML = `
    <i class="fas fa-check"></i>
  `
  couponText.innerHTML = `Cupom copiado`;

  couponButton.classList.add('no-pointer-events')

  setTimeout(() => {
    couponIcon.innerHTML = `
       <i class="fas fa-copy"></i>
    `
    couponButton.classList.remove('no-pointer-events')

    couponText.innerText = getCouponText;
  }, 1500);
}

/* Função para carregar mais produtos ao clicar em "Ver mais" */
const loadMore = (companyIndex) => {
  productsToShow.value[companyIndex] += 4;
};


/* Ir para o ecommerce do parceiro */
const goToStore = ( linkStore ) => {
  window.open(linkStore, '_blank')
} 

</script>
<style scoped>
.products {
  margin: 0px 0.6rem;
}

.products .section-products-by-category {
  position: relative;
}

.products .section-products-by-category > div {
  background-color: #fff;
  padding: 20px;
  max-width: 1180px;
  margin: 35px auto;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
  border-radius: 8px;
}

.products .products-by-category 
{
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 8px;
    justify-content: start;
    box-sizing: border-box
}

.product {
  box-shadow: none !important;
  box-sizing: border-box; /* Inclui padding e bordas no tamanho total */
  margin: 0; /* Remove margens que possam interferir no layout */
  padding: 0; /* Remova ou ajuste o padding se necessário */
  width: 100% !important;
}

.company-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-header .btn-go-to-store {
  display: none;
}

.logo {
  width: 85px;
}

.logo :deep(img) {
  max-width: 110px;
  width: 100%;
}

.btn-go-to-store {
  color: #fff;
  background-color: #222;
  text-decoration: none;
  transition: background-color 250ms ease;
}

.btn-go-to-store:hover {
  background-color: rgba(0,0,0, 0.8);
}

.btn-coupon {
  border: 1px dashed rgba(7, 6, 6, 0.24);
  display: flex;
  gap: 10px;
  align-items: center;
}

.btn-coupon:hover {
  background-color: #f8fafc;
}

.btn-coupon i {
  font-size: 0.9rem
}

.btn-coupon :deep(span) {
  font-size: clamp(10px, 2.8vw, 13px) !important;
}

.company-header .btn-mobile-options {
  display: flex;
  align-items: center;
}

.company-header .btn-mobile-options .q-icon {
  font-size: 1.3rem;
}

.q-item :deep(.q-item__section) {
  white-space: nowrap;
}

.company-header-inner {
  display: flex;
  gap: 8px
}

.company-header-inner a:not(.btn-mobile-options) {
  height: fit-content;
  cursor: pointer;
  width: fit-content;
  padding: 0.3rem 1.5rem;
  border-radius: 100px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #e2e8f0;
  margin: 15px 0px 35px 0px;
}

.company-slug {
    display: flex;
    align-items: center;
    text-align: center;
    color: #000;
    font-weight: 400 !important;
    font-size: clamp(18px, 4vw, 22px) !important;
    margin: 0px
}

.see-more {
  cursor: pointer;
  width: fit-content;
  display: flex;
  justify-content: center;
  margin: 1rem auto 0px auto;
  text-align: center;
  padding: 0.3rem 3rem;
  border-radius: 100px;
  font-weight: 400;
  color: #121212;
  border: 1px solid #cbd5e1;
  cursor: pointer;
}

.see-more:hover {
  background-color: #f8fafc;
}

@media ( max-width: 767px ) {
  .btn-coupon :deep(span) {
    line-height: 0;
    top: 0.03rem;
    position: relative;
  }
}

/* Ajuste para smartphones em landscape */
@media (max-height: 500px) and (orientation: landscape) {
  .container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (min-width: 768px) {
  .products .default,
  .products .products-by-category
  {
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      gap: 12px;
  }

  .company-header-inner a:not(.btn-mobile-options) {
    padding: 0.5rem 1.5rem;
  }

  .company-header a.btn-go-to-store {
    display: block;
  }

  .company-header a.btn-coupon {
    border: 1px dashed rgba(7, 6, 6, 0.24);
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .company-header a.btn-mobile-options {
    display: none;
  }
  
  .company-header .btn-coupon i {
    font-size: 1rem
  }

  .logo {
    max-width: 110px;
    min-width: 110px;
    max-height: 44px;
    min-height: 44px
  }
}

</style>
