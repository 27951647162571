<template>
  <div 
    class="influ-header row"
    :class="{ 'lp-centralized': isLPCentralized }"
  >
    <div class="col-12" :class="{ 'col-md-9': !isLPCentralized }">
      <q-item>
        <q-item-section avatar>
          <q-avatar class="avatar">
            <img
              :src="ecommData.partner_photo"
              :alt="ecommData.highlight_text"
            />
          </q-avatar>
        </q-item-section>

        <q-item-section>
          <q-item-label class="influ-header-inner">
            <div class="influ-header-text" v-text="ecommData.highlight_text" />
          </q-item-label>
          <q-item-label caption class="q-mt-lg description" v-text="ecommData.highlight_description" />
          <InfluSocialNetworks 
            v-if="hasSocialNetworks?.length" 
            :socialNetworks="hasSocialNetworks"
            :isBigScreen="$q.screen.gt.sm" 
            :isLPCentralized="isLPCentralized"
          />
        </q-item-section>
      </q-item>
    </div>
    <div class="col-12 col-md-3 text-center">
      <div class="action-buttons" style="display: flex">
        <div
          v-if="
            ecommCustomization?.voucher
          "
          class="voucher-content"
        >
          <span>
            <div v-html="voucherDescription" />
            <a 
              v-if="ecommCustomization?.voucher?.discount == 0" 
              role="button"
              @click="seeMoreCouponDescription()"
            >
              Ver Mais
            </a>
          </span>
          <q-btn
            outline
            :label="ecommCustomization.voucher.number"
            class="q-mt-xs full-width btn-voucher"
            @click="copyLink(ecommCustomization.voucher.number)"
          >
              <q-tooltip v-if="$q.screen.gt.sm">
                Copiar cupom de desconto
              </q-tooltip>
              <q-icon class="q-ml-sm" name="content_copy" size="1.3rem" />
          </q-btn>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
// import { getCustomLayoutLP } from "@/shared/helpers/customLayoutHelper";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { defineProps, defineAsyncComponent, computed } from "vue";
import { useQuasar } from 'quasar';

const InfluSocialNetworks = defineAsyncComponent(() => import('./InfluSocialNetworks.vue'));

const props = defineProps({
  ecommData: {
    type: Object,
    required: true,
  },
  ecommCustomization: {
    type: Object,
    required: true,
  },
  isLPCentralized: {
    type: Boolean,
    required: true
  }
});

const $q = useQuasar();



/** Filtra as redes sociais, excluindo a rede 'link_to_store'. 
 * Retorna a lista de redes sociais ( menos link_to_store ) ou um valor false caso não haja redes sociais. 
**/
const hasSocialNetworks = computed(() => {
  const socialNetworks = props.ecommData.social_networks;

  return socialNetworks &&
  socialNetworks.filter(social => 
    social.network !== 'link_to_store'
  )
})


const voucherDescription = computed(() => {
  const voucher = props.ecommCustomization?.voucher;

  if ( !voucher ) return false;

  const typeDiscount = voucher.type_discount

  if ( typeDiscount === "percent" && voucher.discount > 0 ) {
    return `
      Cupom de desconto <b>${voucher.discount}% OFF</b> 
    `
  }

  return `
    ${voucher?.description}
  `
})

const seeMoreCouponDescription = () => {
  $q.dialog({
    title: `Cupom ${props.ecommCustomization?.voucher?.number}`,
    message: props.ecommCustomization?.voucher?.description
  })
}

function copyLink(link) {
  if (!link) {
    notifyError("Nenhum link cupom para cópia.");
    return;
  }

  navigator.clipboard
    .writeText(link)
    .then(() => notifySuccess("Cupom copiado com sucesso!"))
    .catch(() => notifyError("Erro ao copiar o cupom."));
}


</script>

<style lang="scss" scoped>
.influ-header {
  background: #f4f4f4;
  display: flex;
  align-items: center;
  padding: 0.8rem 35px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
  font-family: 'Montserrat';
}


/* INICIO: Estilos aplicados apenas na LP CENTRALIZADA */

.influ-header.lp-centralized {
  background: #fff;
  max-width: var(--max-width__section);
  box-shadow: none;
  border-radius: 8px;
  padding: 0px;
  margin: 0px;
}

.influ-header.lp-centralized > .col-12:last-child {
  display: none;
}

.influ-header.lp-centralized .btn-share {
  margin-top: 0px !important;
  min-height: auto;
  width: 32px !important;
  height: 32px;
}

.influ-header.lp-centralized .btn-share{
  margin-top: 0px !important;
  min-height: auto;
  width: 28px !important;
  height: 28px;

  i {
    font-size: 0.9rem
  }
}

/* FIM: Estilos aplicados apenas na LP CENTRALIZADA */



.avatar {
  width: 45px;
  height: 45px;
}

.avatar img {
  border: 3px solid var(--primary-custom-color);
}

.influ-header > div:last-child {
  margin-top: 10px
}

.influ-header.lp-centralized .q-item {
  padding: 8px 0px 0px 0px;
}

.influ-header:not(.lp-centralized) .q-item {
  padding-top: 0px;
  padding-bottom: 0px
}

.influ-header-inner > div {
  margin-top: 5px
}

.influ-header-text {
  font-size: clamp(14px, 3.5vw, 14.5px);
  line-height: 1.2;
  font-weight: 500 !important;
  color: #3d4c5f;
}

.description {
  margin-top: 0.5rem;
  font-size: clamp(11px, 3.5, 14px);
  font-weight: 400;
  display: none;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.action-buttons .voucher-content {
  font-size: 12px;
  display: grid;
  width: 250px;
  max-width: 250px;
}

.action-buttons .voucher-content span:first-child {
  display: flex;
  white-space: nowrap;
  max-width: 250px;
}

.action-buttons .voucher-content span:first-child div {
  white-space: nowrap;
  max-width: 220px;
  margin: 0 auto;
  text-overflow: ellipsis;
  overflow: hidden;
}

.action-buttons .voucher-content span:first-child a {
  color: var(--primary-custom-color);
  text-decoration: underline;
  cursor: pointer
}

.btn-voucher :deep(.q-btn__content span) {
  font-size: 12px
}

.btn-voucher::before {
  border: 1px dashed;
}

.share-with-friends {
  font-size: clamp(12.5px, 3vw, 14px);
}

.btn-share {
  background-color: var(--primary-custom-color);
  color: #fff;
  align-self: end;
  width: fit-content !important
}

.btn-share:hover {
  opacity: 0.85
}

@media ( max-width: 767px ) {
  .action-buttons {
    justify-content: center;
  }

  .description {
    line-height: 1.3;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    height: calc(2* 1.35em);
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 2rem;
  }
}

@media (min-width: 768px) {
  .avatar {
    width: 75px;
    height: 75px;
  }

  .influ-header {
    padding: .5rem 60px
  }

  .influ-header > div:last-child {
    margin-top: 0px;
  }
}
</style>
