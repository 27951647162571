<template>
    <q-card
      v-if="data.full_url"
      class="card"
      :class="{ 'lp-centralized': isLPCentralized }"
      flat bordered
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @click="handleClick"
    >
    <div class="card-header">
      <a 
        @click.stop.prevent="sharedProduct"
        class="btn-share" 
        role="button"
      >
          <q-icon name="shared" :size="iconSize" />
          <q-tooltip v-if="$q.screen.gt.sm">
          Compartilhar
          </q-tooltip>
      </a>
      <shareMenu 
        ref="refShareMenu" 
        :title="data.name"
        :link="data.shared_link"
      />
      <q-img :src="data.full_url" loading="lazy" @error="imageError"/>
    </div>
  
      <q-card-section>
        <q-item-label caption class="text-grey" style="font-size: 8pt">
          {{ data.brand_name }}
        </q-item-label>
        <div class="q-mt-sm q-mb-xs card-title" v-html="data.name" />
        <div class="q-mb-sm price-container">
          <div v-if="data.sale_price">
            <span
              :class="
                data.price_ref > data.sale_price ? 'discount' : 'non-discount'
              "
              >{{ formatToRealBR(data.price_ref) }}</span
            >
            <span class="current-price"
              ><b>{{ formatToRealBR(data.sale_price) }}</b></span
            >
          </div>
  
          <div v-else>
            <span :class="'non-discount'">{{
              formatToRealBR(data.price_ref)
            }}</span
            >
            <span class="current-price"
              ><b>{{ formatToRealBR(data.price_ref) }}</b></span
            >
          </div>
  
          <q-btn
            flat
            v-if="hover || isMobile"
            label="Ver produto"
            class="btn-add-to-cart"
            @click="handleClick"
            :style="`background-color: ${
              layout.body_background !== '#E0E0E0'
                ? layout.menu_top_background
                : '#000'
            };
              color: ${layout.menu_itens_text_color};
            `"
          />
        </div>
      </q-card-section>
    </q-card>
  </template>
  
  <script setup>
  import { defineProps, ref, onMounted, onUnmounted, computed, defineAsyncComponent } from "vue";
  import { getCustomLayoutLP } from "@/shared/helpers/customLayoutHelper";
  import { notifyInfo } from "@/shared/helpers/notify";
  import { checkIfNativeSharingIsAvailable, nativeSharing } from "@/shared/helpers/share"
  import { useQuasar } from "quasar";
  
  // Components
  const shareMenu = defineAsyncComponent(() => import('@/modules/main/components/shareMenu.vue'));
  // Components
  
  const hover = ref(false);
  const refShareMenu = ref(null);
  const isMobile = ref(window.innerWidth <= 900)
  
  const props = defineProps({
    data: {
      type: Object,
      required: true,
    },
     isLPCentralized: {
      type: Boolean,
      default: false
     }
  });
  
  const $q = useQuasar();
  
  const layout = ref(getCustomLayoutLP("_LP"));
  
  const handleClick = () => {
    window.open(
      props.data.shared_link,
      "_blank"
    );
  }
  
  
  const iconSize = computed(() => $q.screen.lt.md ? '1.1rem' : '1.2rem')
  
  
  /**
   * Função para compartilhar um produto.
   * 
   * Verifica se o compartilhamento nativo está disponível no dispositivo. 
   * Se disponível, utiliza a funcionalidade de compartilhamento nativo. 
   * Caso contrário, abre um menu de compartilhamento customizado.
  */
  const sharedProduct = async () => {
      if ( checkIfNativeSharingIsAvailable() ) {
          try {
              nativeSharing({
                  title: props.data.name,
                  text: `${props.data.name}: ${props.data.shared_link}` 
              })
          } catch (error) {
             notifyInfo('Houve um erro ao compartilhar o produto')
          }
      } else {
          refShareMenu.value.openMenu(event.currentTarget)
      }
  }
  
  
 /** Atualiza a variável reativa `isMobile` verificando se a largura da janela (viewport)
  * é menor ou igual a 900 pixels. Indica se o dispositivo é considerado "mobile" 
  **/
  const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 900;
  };
  
  function formatToRealBR(value) {
    return `R$ ${Number(value).toFixed(2)}`;
  }
  
  const imageError = () => console.log('image error')
  
  // Adicionar event listener para redimensionamento da tela
  onMounted(() => {
    window.addEventListener("resize", updateIsMobile);
  });
  
  onUnmounted(() => {
    window.removeEventListener("resize", updateIsMobile);
  });
  </script>
  
  <style lang="scss" scoped>
  .card {
    flex: 0 0 auto;
    box-sizing: border-box;
    border: none;
    box-shadow: 0 0 0 1px rgba(0,0,0,.05) !important;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
  }

  .card.lp-centralized {
    max-width: none;
    min-width: auto;
    margin: 0; 
  }
  
  .card-title {
    font-size: clamp(11px, 4vw, 12.5px);
    font-weight: 500;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    height: calc(2 * 1.35em);
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 2rem;
    font-family: "Montserrat", sans-serif !important;
  }
  
  .card-header {
    max-height: 150px;
  }
  
  .card-header .q-img{
    max-height: 150px;
    max-width: 150px;
    margin: 0 auto;
    border-radius: 6px;
  }
  
  .card-header .q-img :deep(img){
    object-position: bottom !important;
  }
  
  .q-img {
    display: flex;
  }
  
  .btn-share {
    right: 0.4rem;
    position: absolute;
    top: 0.3rem;
    z-index: 2;
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-height: 1.4;
    -webkit-box-orient: vertical;
    font-family: "Montserrat", sans-serif !important;
    overflow: hidden;
  }
  
  .btn-add-to-cart {
    position: fixed;
    bottom: 10px;
    left: 10px;
    font-size: clamp(11px, 4vw, 12.5px);
    border-radius: 100px;
    text-transform: initial;
    width: calc(100% - 20px);
    margin: 0 auto;
    opacity: 0;
    transition: opacity 0.3s ease;
    display: none;
  
    @media (max-width: 900px) {
      position: relative;
      margin-left: auto;
      margin-right: 0.5rem;
      top: 0.1rem;
      width: 42px;
      opacity: 1;
      transition: none;
    }
  }
  
  .btn-add-to-cart :deep(.q-icon) {
    font-size: 1.5rem;
  }
  
  .card:hover .btn-add-to-cart {
    opacity: 1;
  
    @media (max-width: 900px) {
      opacity: 1;
    }
  }
  
  .price-container {
    display: grid;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    font-family: "Montserrat", sans-serif !important;

    
    div {
        display: grid;   
    }
  
    @media (max-width: 900px) {
      justify-content: flex-start;
    }
  }
  
  .current-price b {
    color: #3a3a3a;
    font-size: clamp(12.5px, 3.5vw, 15px);
  }
  
  .discount {
    text-decoration: line-through;
    font-size: clamp(10px, 3.5vw, 12px);
    color: #999;
  }
  
  .non-discount {
    display: none;
    font-size: 10px;
  }
  
  @media ( min-width: 768px ) {
    .card {
        min-width: 200px;
        max-width: 200px;
        height: 288px;
    }

    .card.lp-centralized {
      min-width: 220px;
      max-width: 220px;
    }
    
    .card-header .q-img{
      max-height: 138px;
      max-width: 142px;
    }
  
    .card:hover {
      scale: 1.02;
      transition: 0.5s;
    }
    
    .btn-add-to-cart :deep(.q-icon) {
      font-size: 1.715em;
    }
  }
  </style>
  