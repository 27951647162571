<template>
  <div 
    v-if="!in_request" 
    class="public-store"
    :class="{ 'lp-centralized': isLPCentralized }"
  >
    <div class="container" v-if="Object.keys(getCompanieDetails).length">
      <Header 
        :companyDetails="getCompanieDetails" 
        :getProductsByCategories="getProducts"
        :ecommCustomization="getInfluEcomCustomization?.ecomm_customization"
        :scrollTo="scrollTo"
        :isLPCentralized="isLPCentralized"
      />
    </div>

    <InfluHeader
      v-if="Object.keys(getInfluData).length && !isLPCentralized"
      class="influ-header"
      :ecommData="getInfluData"
      :ecommCustomization="getInfluEcomCustomization"
      :isLPCentralized="isLPCentralized"
    />
    

    <!--- Exibir apenas na LP Centralizada  -->
    <Companies 
      v-if="isLPCentralized"
      :companiesData="getOnlyCompaniesWithProductsLPCentralized" 
      :scrollTo="scrollTo"
    />


    <template v-if="Object.keys(getProducts).length">
      <div>
        <ProductsInCentralized 
          v-if="isLPCentralized"
          :productsByCompany="getProducts" 
        />
        <ProductsInDefault 
          v-else
          :products="getProducts" 
        />
      </div>
    </template>
    <template v-else>
      <div
        class="q-pa-xl text-center text-h5 flex flex-center"
        style="height: 490px"
      >
        Não foram encontrados itens favoritos.
      </div>
    </template>

    <Footer
      :influ="getInfluData"
      :company="getCompanieDetails"
      :prodsByCat="getProducts"
      v-if="
        Object.keys(getInfluData).length &&
        Object.keys(getCompanieDetails).length
      "
    />
    <div 
      class="create-store" 
      :class="{'minimized': showCreateStoreMinimized}"
    >
      <div class="create-store-maximized" 
      v-if="!showCreateStoreMinimized"
      @click="createMyStore.goToCreateStore()"
      >
        <q-btn class="btn-close-store" round @click.stop="createMyStore.minimized()">
          <q-icon name="close" size="1rem" />
        </q-btn>
        <div class="create-store-content">
          <q-img src="https://flipnet-assets.s3.sa-east-1.amazonaws.com/fronts/public/assets/ecommerce.png" />
          <div class="create-store-inner">
            <h4 v-text="textCreateMyStore" />
            <a role="button">Saiba mais</a>
          </div>
        </div>
      </div>
      <div v-else class="create-store-minimized" @click.stop="createMyStore.maximized()">
        <q-icon name="storefront" size="1.5rem" />
      </div>
    </div>

  </div>
  <div v-else>
    <q-inner-loading
      :showing="in_request"
      label="Carregando loja..."
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script setup>
import {
  setCustomLayoutLP,
  getCustomLayoutLP,
} from "@/shared/helpers/customLayoutHelper";
// import CarouselMultiItensComponent from "./components/Carousel.vue";
import InfluHeader from "./components/InfluHeader.vue";
import { baseURL } from "@/shared/http/api";
import axios from "axios";

import { useActions, useGetters } from "vuex-composition-helpers";
import { useRoute } from "vue-router";
import { computed, onMounted, ref } from "vue";
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import ProductsInCentralized from "./components/lp-centralized/Products.vue";
import ProductsInDefault from "./components/lp-default/Products.vue";
import Companies from "./components/lp-centralized/Companies.vue";
import { getFirstWord } from "../../../../shared/helpers/stringManipulations";

const route = useRoute();
const in_request = ref(true);
const showCreateStoreMinimized = ref(localStorage.getItem('public_landingpage-show_create_store_minimized'))

const { childSlug, parentSlug } = route.params

const {
  getProducts, getInfluData, getCompanieDetails,
  getInfluEcomCustomization, isLPCentralized,
  getOnlyCompaniesWithProductsLPCentralized
} = useGetters({
  getProducts: "public_landingpage/getProducts",
  getInfluData: "public_landingpage/getInfluData",
  getCompanieDetails: "public_landingpage/getCompanieDetails",
  getInfluEcomCustomization: "public_landingpage/getInfluEcomCustomization",
  isLPCentralized: "public_landingpage/isLPCentralized",
  getOnlyCompaniesWithProductsLPCentralized: "public_landingpage/getOnlyCompaniesWithProductsLPCentralized"
});

const {
  fetchInfluProducts, fetchInfluEcomCustomization,
  fetchCompanieDetails, setLPCentralized,
  fetchLinksAndVouchers
} = useActions({
  fetchInfluProducts: "public_landingpage/fetchInfluProducts",
  fetchInfluEcomCustomization: "public_landingpage/fetchInfluEcomCustomization",
  fetchCompanieDetails: "public_landingpage/fetchCompanieDetails",
  setLPCentralized: "public_landingpage/setLPCentralized",
  fetchLinksAndVouchers: "public_landingpage/fetchLinksAndVouchers"
});


const layout = ref(getCustomLayoutLP("_LP"));


const textCreateMyStore = computed(() => {
  const firstName = getFirstWord(getInfluEcomCustomization.value?.ecomm_customization?.partner?.friendly_name);
  
  return `Quer ter uma loja igual a da ${firstName}?`
});


const scrollTo = (title) => {
  document.getElementById(title).scrollIntoView({ behavior: "smooth" });
};


/*  Objeto com os metodos do popup 'Quero ter uma loja igual a fulano'  */
const createMyStore = {
  goToCreateStore() {
    const origin = isLPCentralized.value ? 
    'https://flip.net.br/cadastro/' : 
    `https://go.flip.net.br/${childSlug}/sign-up/`;

    // Parâmetros UTM para rastreamento
    const utms = '&utm_source=public_landingpage&utm_medium=popup&utm_campaign=create_my_store';
    const indicatedBy = `?indicated_by=${getInfluEcomCustomization.value.ecomm_customization?.partner?.slug}`;

    const url = `${origin}${indicatedBy}${utms}`;

    window.open(url, '_blank');
  },

  minimized() {
    showCreateStoreMinimized.value = true;

    localStorage.setItem('public_landingpage-show_create_store_minimized', true)
  },

  maximized() {
    showCreateStoreMinimized.value = false;

    localStorage.removeItem('public_landingpage-show_create_store_minimized')
  }
}


const fetchParam = (param) =>
  axios.get(
    `${baseURL}/api/v1/parameters/p/${childSlug}/${param}`
  );


if ( childSlug === "flipnet" ) setLPCentralized(true)
else setLPCentralized(false)


onMounted(() => {
  const payload = {
    slug: childSlug,
    parentSlug: parentSlug,
  };

  
  const promises = [
    fetchParam("custom_layout")
      .then(({ data }) => {
        setCustomLayoutLP(data.value);

        if ( childSlug === "flipnet" ) {
          return fetchLinksAndVouchers({ parentSlug: parentSlug })
        }

        return Promise.resolve()
      })
      .then(() => (layout.value = getCustomLayoutLP("_LP"))),
    fetchInfluProducts(payload),
    fetchInfluEcomCustomization(payload),
    fetchCompanieDetails({ slug: childSlug }),
  ]


  Promise.all(promises)
  .finally(() => (in_request.value = false));
});
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.public-store.lp-centralized {
  background-color: #ebebeb;
}

.container {
  padding: 0px;
  font-family: "Montserrat", sans-serif !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.create-store {
  background-color: #fff;
  bottom: 3.8rem;
  position: fixed;
  z-index: 10;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px
}

.create-store-maximized  {
  padding: 1.2rem;
  width: fit-content;
}

.create-store-minimized {
  padding: 1rem
}

.create-store.minimized:hover {
  background-color: #f8fafc;
}

.create-store-content {
  display: flex;
  align-items: center;
  place-items: center;
  gap: 0.5rem;
}

.create-store-inner {
  display: grid;
  gap: 0.5rem;
}

.create-store-inner h4 {
  margin: 0px;
  font-weight: 400;
  font-size: clamp(15.5px, 3.5vw, 16px);
  line-height: 1.2;
  color: #222f42;
}

.create-store a {
  display: flex;
  margin-left: auto;
  color: #222f42;
  text-decoration: none;
  border: 1px solid #e2e8f0;
  border-radius: 40px;
  padding: 0.4rem 1rem;
  width: fit-content;
  font-size: clamp(12px, 3.5vw, 13px);
}

.create-store .q-img {
  width: 170px;
}

.btn-close-store {
  position: absolute;
  right: -0.5rem;
  top: -1rem;
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .15);
  min-width: 2rem;
  min-height: 2rem;
  z-index: 11;
}

@media ( max-width: 767.99px ) {
  .create-store:not(.minimized) {
    width: 90%;
    transform: translateX(-50%);
    left: 50%;
  }

  .create-store.minimized {
    right: 1rem
  }
}

@media ( min-width: 768px ) {
  .create-store {
    max-width: 350px;
    right: 1rem;
  }

  .create-store .q-img {
    width: 150px;
  }

  .create-store a {
    margin-top: -0.2rem;
  }  

  .create-store a:hover {
    background-color: #f8fafc;
  }
}
</style>
