<template>
    <div 
        class="header" 
        :class="{ 'lp-centralized': isLPCentralized }"
    >
        <div class="header-inner">

            <div class="header-left">
                <InfluHeader
                    class="influ-header"
                    v-if="Object.keys(getInfluData).length && isLPCentralized"
                    :ecommData="getInfluData"
                    :ecommCustomization="ecommCustomization"
                    :isLPCentralized="isLPCentralized"
                />
                <q-img 
                    v-else
                    :src="companyDetails?.logo" class="logo" 
                    @click="goToExternalStore"
                />
            </div>

            <div class="header-center">
                <q-form @submit.prevent="menuSearchResults.open()">
                    <q-input
                        ref="refSearchInput" 
                        outlined 
                        autocomplete="off"
                        @click="menuSearchResults.open()"
                        placeholder="O que você procura hoje?"
                        menu-anchor="none"
                        v-model="searchQuery"
                    >
                        <q-btn type="submit" flat>
                            <q-icon name="search" />
                        </q-btn>
                    </q-input>
                </q-form>
                <MenuSearchResults
                    v-if="showSearchResults"
                    :getAllProducts="getAllProducts" 
                    :searchQuery="searchQuery"
                    :closeSearchResults="menuSearchResults.close"
                    :position="searchResultsPosition"
                    :isLPCentralized="isLPCentralized"
                />
            </div>

            <div class="header-right">

              <!--- Utilizado apenas na LP da Marca ( nao na centralizada ) --->  
                <div v-if="!isLPCentralized">
                    <q-btn-dropdown
                        split
                        color="teal"
                        :label="'Ir para ' + companyDetails?.name.split(',').slice(0, 2).join()"
                        flat
                        v-if="$q.screen.width > 599" @click="goToExternalStore"
                    >
                    <q-list class="dropdown-list-lp">
                        <q-item>
                            <q-btn
                                flat
                                class="q-mt-xs full-width btn-share"
                                @click="
                                    share(
                                        sanitizeLinkWithSlug(
                                        ecommCustomization?.link_to_store, 
                                        ecommCustomization?.partner?.slug,
                                        )
                                    ) 
                                "
                            >
                                <q-icon name="share" size="1.1rem"/>
                                <span class="q-ml-sm">Compartilhar</span>
                            </q-btn>
                        </q-item>
                    </q-list>
                    </q-btn-dropdown>

                    <div v-else>
                        <q-btn flat color="black" icon="menu" @click="setSidebarStatus(true)" />
                    </div>     
                </div>
                 <!--- Utilizado apenas na LP da Marca --->  
            </div>
        </div>
    </div>
    
    <Tabs 
        v-if="canShowTabs" 
        :data="getProductsByCategories" 
        :scrollTo="scrollTo"
    />

    <Sidebar 
        :data="getProductsByCategories" 
        :scrollTo="scrollTo" 
        :handleMenuClick="handleMenuClick"
    />
</template>
<script setup>
import { computed, defineProps, nextTick, onMounted, onUnmounted, reactive, ref } from 'vue';
import { useActions, useGetters } from 'vuex-composition-helpers';
import { useRoute } from 'vue-router';
import { useQuasar } from 'quasar'; 
import { checkIfNativeSharingIsAvailable, nativeSharing } from '@/shared/helpers/share'

import Sidebar from './Sidebar.vue';
import Tabs from './Tabs.vue';
import MenuSearchResults from './MenuSearchResults.vue';
import InfluHeader from './InfluHeader.vue';


const props = defineProps({
    companyDetails: Object,
    getProductsByCategories: Object,
    scrollTo: Function,
    ecommCustomization: Object,
    isLPCentralized: {
        required: true,
        type: Boolean,
        default: false
    }
})

const refSearchInput = ref(null)
const searchQuery = ref('')
const showSearchResults = ref(false);

// Posição dinamica do Menu de produtos pesquisados, de acordo com a posição do q-input
const searchResultsPosition = reactive({}) 

const $q = useQuasar();
const route = useRoute();
const { childSlug } = route.params;

const { getAllProducts, isLPCentralized, getInfluData } = useGetters({
    getAllProducts: 'public_landingpage/getAllProducts',
    isLPCentralized: "public_landingpage/isLPCentralized",
    getInfluData: "public_landingpage/getInfluData",
})

const { setSidebarStatus } = useActions({
    setSidebarStatus: 'public_landingpage/setSidebarStatus'
})


/**
 *  Exibe AS TABS se a largura da tela for maior que 900px ,
 * houver produtos por categorias disponíveis,
 * e a página não estiver na LP centralizada.
* */
const canShowTabs = computed(() => 
    $q.screen.width > 900 && 
    Object.entries(props.getProductsByCategories).length &&
    !isLPCentralized.value
)


/* =============== METHODS =============== */

const handleMenuClick = (title) => {
 setSidebarStatus(false);
 menuSearchResults.close()

  nextTick(() => {
    props.scrollTo(title);
  });
};


/* Compartilha a LP do influenciador */
const share = async (text) => {
    if ( checkIfNativeSharingIsAvailable ) {
        try {
            await nativeSharing({
                title: props.ecommCustomization?.highlight_text,
                text: text
            })
        } catch (error) {
            console.log('Houve um erro ao compartilhar')
        }
    }
}


/* Vai para a o site do parceiro */
const goToExternalStore = () => {
    const linkToStore = props.ecommCustomization?.link_to_store;
    const influencerSlug = props.ecommCustomization?.partner?.slug;


    if (!linkToStore || !influencerSlug) {
        console.error("Link ou slug do parceiro não definido.");
        return;
    }

    window.open(sanitizeLinkWithSlug(linkToStore, influencerSlug), '_blank');
}


/**
 * Substitui {slug_parceiro} na URL caso exista e ajusta parâmetros bugados.
 * @param {string} linkToStore - URL original com possíveis placeholders para o slug.
 * @param {string} influencerSlug - Slug do influenciador que será inserido.
 * @returns {string} - URL corrigida e sanitizada.
 */
 const sanitizeLinkWithSlug = (linkToStore, influencerSlug) => {
    if (!linkToStore || !influencerSlug) {
        throw new Error("Link ou slug do parceiro está ausente.");
    }

    // Substituir corretamente apenas {slug_parceiro}
    const updatedLinkToStore = linkToStore.replaceAll('{slug_parceiro}', influencerSlug);

    // Validar e ajustar parâmetros bugados
    const url = new URL(updatedLinkToStore);
    url.searchParams.forEach((value, key) => {
        // Corrige caso algum parâmetro esteja bugado
        if (value.includes(influencerSlug) && value !== influencerSlug) {
            url.searchParams.set(key, influencerSlug); // Define apenas o slug
        }
    });

    return url.toString();
};


const menuSearchResults = {
    open() {
        showSearchResults.value = true;
        bodyScroll.enabled();


        const inputElement = refSearchInput.value.$el.getBoundingClientRect();

        // Define as coordenadas do menu
        searchResultsPosition.top = `${inputElement.bottom + window.scrollY}px`;
        searchResultsPosition.left = `${inputElement.left + window.scrollX}px`;
        searchResultsPosition.width = `${inputElement.width}px`;
    },

    close() {
        showSearchResults.value = false;
        bodyScroll.disabled();
    }
}

/* Funções para desabilitar e habilitar o scroll do body */
const bodyScroll = {
    enabled: () => {
        document.body.classList.add('no-scroll')
    },

    disabled: () => {
        document.body.classList.remove('no-scroll')
    }
}

/* Caso o menu de produtos pesquisados esteja aberto, detecta quando 'ESC' é pressionado para fecha-lo */
const handleEscapeKey = (event) => {
    if ( showSearchResults.value ) {
        if (event.key === 'Escape' || event.key === 'Esc') {
            menuSearchResults.close()
        }
    }
}

/* Define valores personalizados para as variaveis, quando o parceiro é FLIPNET */
const setCSSVariables = () => {
    if ( childSlug === "flipnet" ) {
        const el = document.documentElement;

        el.style.setProperty('--width__logo-desktop', '75px');
        el.style.setProperty('--top__menu-lp-searchbar', '72px');
    }
}

/* =============== METHODS =============== */


onMounted(() => {
    document.addEventListener('keydown', handleEscapeKey)
    setCSSVariables()
})

onUnmounted(() => document.removeEventListener('keydown', handleEscapeKey))

</script>
<style scoped>
.header {
    padding: 0.7rem 0.6rem;
    background-color: #fff;
}

/* INICIO: Estilos aplicados apenas na LP CENTRALIZADA */

.header.lp-centralized .header-inner {
 display: grid;
 justify-content: normal;
}

.header.lp-centralized .header-center {
    order: 1;
}


.header.lp-centralized .header-left {
    order: 2;
}

.header.lp-centralized .header-right {
    order: 3;
}

/* FIM: Estilos aplicados apenas na LP CENTRALIZADA */

.header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.header-center {
    flex: 1;
    display: flex;
    justify-content: center;
}

.header-right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-right > div .q-btn {
    padding: 0px 0.1rem;
}

.header-right .btn-share .q-icon {
    font-size: 1.35rem;
}

.header-right .q-btn-dropdown {
    transition: all 250ms ease;
}

.header-right .q-btn-dropdown :deep(.q-btn) {
    border: 1px solid #cbd5e1;
    border-radius: 6px;
}

.header-right .q-btn-dropdown :deep(.q-btn .block),
.header-right .q-btn-dropdown :deep(.q-btn .q-icon)
{
    color: #3d4c5f;
}

.header-right .q-btn-dropdown :deep(.q-btn:first-child) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.header-right .q-btn-dropdown :deep(.q-btn:first-child:hover) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #f8fafc !important;
}

.header-right .q-btn-dropdown :deep(.q-btn:last-child) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
}

.header-right .q-btn-dropdown :deep(.q-btn) {
    color: #000;
    text-transform: initial;
    font-size: clamp(11px, 3.5vw, 13px);
}

.header-right .q-btn-dropdown :deep(.q-btn:last-child) {
    background-color: #f8fafc !important;
}


.header-right .q-btn-dropdown .q-btn:first-child {
    border-radius: 6px;
    transition: background-color 250ms ease;

}

.header-right .q-btn-dropdown .q-btn:first-child:hover {
    background-color: #f4f4f4;
}

.q-form {
    width: 100%;
}

.header.lp-centralized .q-form {
    max-width: 100%;
}

.q-form :deep(i) {
    color: var(--primary-custom-color)
}

.q-form :deep(.q-field__control) {
    height: 45px;
}

:deep(.q-field--outlined .q-field__control) {
    height: 55px
}

:deep(.q-field--outlined .q-field__control) {
    background-color: #f4f4f4;
    border-radius: 6px;
}

:deep(.q-field--outlined .q-field__control:before) {
    border: none;
}

.logo {
    width: var(--width__logo-mobile);
    cursor: pointer;
}

.external-link.text-primary {
    color: var(--primary-custom-color) !important;
}

.dropdown-list-lp .q-btn {
    font-weight: 400;
    text-transform: initial;
    color: #3d4c5f;
}

@media ( max-width: 767px ) {
    :deep(.q-field .q-btn) {
        padding-right: 0px;
    }

    :deep(.btn-add-to-cart) {
        max-width: 42px;
        max-height: 25px;
    }

    :deep(.btn-add-to-cart .q-btn__content) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :deep(.btn-add-to-cart .q-icon) {
        font-size: 1.2rem;
    }
}

@media ( min-width: 768px ) {
    .header {
        padding: 1rem;
    }

    .logo {
        width: var(--width__logo-desktop);
    }

    .q-form {
        max-width: 700px;
    }

    .header.lp-centralized .header-inner {
        max-width: 1180px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .header.lp-centralized .header-left {
        order: 1;
        max-width: 300px;
    }

    .header.lp-centralized .header-center {
        order: 2;
    }

    .header.lp-centralized .header-right {
        order: 3;
    }
}
</style>
